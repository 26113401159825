<template>
  <div :bordered="false">
    <div class="table-page-search-wrapper">
      <a-form layout="inline" @keyup.enter.native="searchQuery">
        <a-row :gutter="24">
          <a-col :md="8" :sm="12">
            <a-form-item label="放款失败日期">
              <a-range-picker v-model="queryParam.time" format="YYYY-MM-DD" />
            </a-form-item>
          </a-col>
          <a-col :md="6" :sm="8">
            <a-form-item :label="$t('phoneNo')">
              <a-input v-model="queryParam.mobileNo"></a-input>
            </a-form-item>
          </a-col>
          <a-col :md="6" :sm="8">
            <a-form-item :label="'订单编号'">
              <a-input v-model="queryParam.orderNo"></a-input>
            </a-form-item>
          </a-col>


          <a-col :md="8" :sm="12">
            <a-form-item :label="'系统身份证号'">
              <a-input placeholder="" v-model="queryParam.curp"></a-input>
            </a-form-item>
          </a-col>



          <a-col :md="6" :sm="8">
            <a-form-item :label="'处理状态'">
              <a-select default-value="" style="width: 120px" v-model="queryParam.status">

                <a-select-option value="0">
                  未处理
                </a-select-option>
                <a-select-option value="1">
                  已处理
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>

          <a-col :md="6" :sm="8">
            <span style="float: left;overflow: hidden;" class="table-page-search-submitButtons">
              <a-button type="primary" @click="searchQuery" icon="search">{{ $t('search') }}</a-button>
              <a-button type="primary" @click="searchReset" icon="reload" style="margin-left: 8px">{{ $t('reset')
                }}</a-button>

            </span>
          </a-col>
        </a-row>
      </a-form>

      <a-table ref="table" rowKey="id" size="middle" :columns="columns" :dataSource="dataSource" :pagination="false"
        :loading="loading" :scroll="{ x: 1000 }" bordered @change="handleTableChange">
        <span slot="status" slot-scope="text,record">{{ record.status == '0' ? '未处理' : record.status == 1 ? '已处理' : ''
          }}</span>
        <span slot="updateTime" slot-scope="text,record"> {{ transformTime(record.updateTime) }}</span>
        <span slot="action" slot-scope="text, record">
          <a @click="getCustomerDetailMethod(record)">编辑</a>
        </span>
      </a-table>
      <div style="margin-top: 15px; text-align: right;">
        <v-pagination v-if="total" :page-num="queryParam.pageNum" :page-size="queryParam.pageSize" :total="total"
          @change="handleTableChange" />
      </div>
    </div>
    <a-modal v-model="visible" width="1000px" title="详情" @ok="handleOk" @cancel="editCancel">
      <div class="zlBox1">
        <div class="ItemTitle">客户影像资料</div>
        <div class="imageBox" v-if="imageInfos" v-viewer="viewOptions">
          <img :src="item.imgUrl" alt="" v-for="(item, index) in imageInfos" :key="index">
          <!-- <div v-viewer="viewOptions">
            <div style="cursor: pointer" v-for="(item, index) in imageInfos" :key="index" href="">
                <img :src="item.imgUrl" alt="">
            </div>
          </div> -->
        </div>
      </div>
      <div class="zlBox2">
        <div class="ItemTitle">资料信息</div>
        <div class="zlboxCon">
          <div class="formInput">
            <div class="Item">
              <div class="label">系统身份证号：</div>
              <div class="text">{{ recordObj.originCurp }}</div>
            </div>
            <div class="Item">
              <div class="label">修改：</div>
              <a-input v-model="modifiedCurp"></a-input>
            </div>
            <div class="Item">
              <div class="label">状态：</div>
              <a-select default-value="" style="width: 120px" v-model="modifiedstatus">

                <a-select-option value="0">
                  未处理
                </a-select-option>
                <a-select-option value="1">
                  已处理
                </a-select-option>
              </a-select>
            </div>
          </div>
        </div>
      </div>

    </a-modal>
  </div>
</template>

<script>
import { filterObj } from '@/utils/util'
import { orderLoanFailList, updateCurp , orderLoanFailImgInfos} from '@/webpublicapi/order'
// import {imageMixin} from "@/mixins/image-mixin.js"
import imageMixin from '../../../mixins/image-mixin'
import moment from 'moment'
export default {
  name: 'FiledOrderList',
  data() {
    return {
      visible: false,
      // 分页参数
      dataSource: [],
      total: 0,
      // 查询条件
      queryParam: {
        time: [moment().startOf('day').add(-1, 'week').format('YYYY-MM-DD'),moment().startOf('day').format('YYYY-MM-DD')],
        mobileNo: "",
        orderNo: "",
        curp: "",
        status: "",
        pageNum: 1,
        pageSize: 10
      },
      loading: false,
      imageInfos: [],
      recordObj: {},
      modifiedCurp: "",
      modifiedstatus: "",

    }
  },
  mixins: [imageMixin],
  computed: {
    columns() {
      return [
        {
          title: "订单编号",
          align: 'center',
          width: '150px',
          dataIndex: 'orderNo'
        },
        {
          title: "手机号",
          align: 'center',
          width: '110px',
          dataIndex: 'mobileNo'
        },
        {
          title: "系统身份证号",
          align: 'center',
          width: '130px',
          dataIndex: 'originCurp'
        },
        {
          title: "图片身份证号",
          align: 'center',
          width: '130px',
          dataIndex: 'modifiedCurp'
        },
        {
          title: "处理状态",
          align: 'center',
          width: '100px',
          dataIndex: 'status',
          scopedSlots: { customRender: 'status' }
        },
        ,
        {
          title: "处理时间",
          align: 'center',
          width: '100px',
          dataIndex: 'updateTime',
          scopedSlots: { customRender: 'updateTime' }
        },

        {
          title: "处理人",
          align: 'center',
          width: '110px',
          dataIndex: 'updateBy'
        },
        {
          title: "操作",
          dataIndex: 'action',
          align: 'center',
          width: 100,
          scopedSlots: { customRender: 'action' }
        }
      ]
    },
  },
  created() {
    // this.loadData(1)
  },
  methods: {
    searchQuery() {
      this.loadData(1)
    },
    editCancel() {
      this.visible = false
      this.recordObj = {}
      this.modifiedCurp = ""
      this.modifiedstatus = ""
    },
    handleOk() {
      this.loading = true
      updateCurp({
        id: this.recordObj.id,
        modifiedCurp: this.modifiedCurp,
        status: this.modifiedstatus,
      }).then(res => {
        if (res.code === 1000) {
          this.$message.success("修改成功")
          this.visible = false
          this.recordObj = {}
          this.modifiedCurp = ""
          this.modifiedstatus = ""
          this.loadData()
        } else {
          this.$message.error(res.msg)
        }
        this.loading = false
      }).catch(e => {
        this.loading = false;
        this.$message.error(e.message);
      })

    },
    getCustomerDetailMethod(record) {
      this.loading = true
      this.recordObj = record
      orderLoanFailImgInfos({ id: record.id }).then(res => {
        if (res.code === 1000) {
          this.imageInfos = res.data.imageInfos
          this.visible = true
        } else {

          this.$message.error(res.msg)
        }
        this.loading = false
      }).catch(e => {
        this.loading = false;
        this.$message.error(e.message);
      })
    },
    loadData(arg) {
      // 加载数据 若传入参数1则加载第一页的内容
      if (arg === 1) {
        // this.form.pageNum = 1
        this.queryParam.pageNum = 1
      }
      this.loading = true
      let obj = {}
      obj.startDate = this.transformTime(this.queryParam.time[0]) ? this.transformTime(this.queryParam.time[0]) : ''
      obj.endDate = this.transformTime(this.queryParam.time[1]) ? this.transformTime(this.queryParam.time[1]) : ''
      obj.mobileNo = this.queryParam.mobileNo
      obj.orderNo = this.queryParam.orderNo
      obj.curp = this.queryParam.curp
      obj.status = this.queryParam.status
      obj.pageNum = this.queryParam.pageNum
      obj.pageSize = this.queryParam.pageSize
      orderLoanFailList(obj).then((res) => {
        if (res.code === 1000) {
          this.total = res.data.total
          this.dataSource = res.data.rows
        } else {
          this.total = 0
          this.dataSource = []
          this.$message.error(res.msg)
        }
        this.loading = false
      }).catch(e => {
        this.loading = false;
        this.$message.error(e.message);
      })
    },
    transformTime(time) {
      return moment(time).format('YYYY-MM-DD')
    },

    handleTableChange(pageNum, pageSize) {
      this.queryParam.pageNum = pageNum;
      this.queryParam.pageSize = pageSize;
      this.loadData();
    },
    // 重置字典类型搜索框的内容
    searchReset() {
      var that = this
      that.queryParam.orderNo = ''
      that.queryParam.mobileNo = ''
      that.queryParam.time = []
      that.queryParam.curp = ''
      that.queryParam.status = '';
      that.loadData(this.queryParam.pageNum)
    }

  }
}
</script>
<style lang="less" scoped>
@import "~@/assets/less/common.less";

.ItemTitle {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 20px;
  position: absolute;
  left: 0px;
  top: 0px;
  height: 40px;
  line-height: 40px;
  width: 100%;
  padding: 0px 20px;
  background-color: #eee;
}

.imageBox {
  width: 100%;
  padding: 10px;
  display: flex;
  flex-wrap: wrap;
}

.imageBox img {
  width: 120px;
  height: 160px;
  margin-right: 20px;
}

.zlBox1 {
  margin-bottom: 30px;
  border: 1px solid #bbb;
  border: 1px solid #bbb;
  padding: 20px;
  padding-top: 50px;
  position: relative;
}

.zlBox2 {
  width: 100%;
  min-height: 200px;
  border: 1px solid #aaa;
  padding: 20px;
  padding-top: 50px;
  position: relative;

}

.zlBox2 .zlboxCon {
  width: 100%;
  display: flex;
}

.formInput {
  width: 400px;
  margin-right: 20px;
}

.formInput .Item {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.label {
  min-width: 50px;
  white-space: nowrap;
  text-align: center;
  font-size: 16px;
}

.Item .ant-input {
  width: 300px;
  padding-left: 10px;
}

#rcDialogTitle1 {
  font-size: 20px !important;
  font-weight: bold !important;
  text-align: center !important;
}

/deep/ .ant-modal-title {
  font-size: 20px !important;
  font-weight: bold !important;
  text-align: center !important;
}
</style>
